/**
 * This file inits the scroll header
 *
 * @package wpbp
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */

export function scrollHeader() {
	const body = document.body;
	const header = body.querySelector("#masthead");

	if (!header) return;

	const hClasses = header.classList;

	// Body Classes
	const goUp = "up";
	const goDown = "down";

	// Variables
	let lastScroll = 0;
	let start = 250;

	//=== === === HEADER DEFAULT === === === //
	if (hClasses.contains("fixed-top")) {
		window.addEventListener("scroll", () => {
			const currentScroll = window.pageYOffset;

			if (currentScroll > start) {
				if (currentScroll === 0) {
					body.classList.remove(goUp);
					return;
				}

				if (
					currentScroll > lastScroll &&
					!body.classList.contains(goDown)
				) {
					body.classList.remove(goUp);
					body.classList.add(goDown);
				} else if (
					currentScroll < lastScroll &&
					body.classList.contains(goDown)
				) {
					body.classList.remove(goDown);
					body.classList.add(goUp);
				}
				lastScroll = currentScroll;
			} else {
				body.classList.remove(goUp);
			}
		});
	}
}
