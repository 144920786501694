/**
 * This functions sets the initial
 * height to main to push the footer down
 * and sets the fixed header.
 *
 * @package wpbp
 */

export function resetPage() {
	// Elementes
	const header = document.getElementById("masthead");
	const footer = document.getElementById("colophon");
	const main = document.getElementById("main-content");

	if (!header || !footer || !main) return;

	// Calculated height
	let mainHeight =
		window.innerHeight - (header.offsetHeight + footer.offsetHeight);

	// Set min-height on main element
	if (main) main.setAttribute("style", "min-height: " + mainHeight + "px");
}

export function pageInit() {
	// hype.media in console
	console.log(
		"%cmade by hype.media siegen.",
		"color:#fff; font-family:sans-serif; font-size: 20px; padding: .25rem; background:#e10019;"
	);
	jQuery(".loading-screen").fadeOut(400);
}
