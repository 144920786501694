/**
 * This file registers all the sliders.
 *
 * @package wpbp
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */
import 'slick-carousel';

export function sliderConfiguration() {
    jQuery(document).ready(function ($) {
        const heroSlider = jQuery('.hero-slide');
        const postSlider = jQuery('.post-slider');
        const wordpressGallerySlider = $('.wp-gallery-slider');
        const contentSlide = $('.content-slider');

        heroSlider.slick({
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 800,
            rows: 0,
            autoplaySpeed: 6000,
            arrows: false,
            fade: true,
            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
            adaptiveHeight: false,
        });

        postSlider.slick({
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 800,
            rows: 0,
            slidesToShow: 3,
            slidesToScroll: 1,
            //centerMode: true,
            //centerPadding: '60px',
            variableWidth: false,
            autoplaySpeed: 2500,
            arrows: true,
            fade: false,
            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });

        contentSlide.slick({
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 800,
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            //centerMode: true,
            //centerPadding: '60px',
            variableWidth: false,
            autoplaySpeed: 2500,
            arrows: true,
            fade: true,
            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
            adaptiveHeight: false,
        });

        wordpressGallerySlider.slick({
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 800,
            rows: 0,
            autoplaySpeed: 4500,
            arrows: true,
            fade: true,
            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
            adaptiveHeight: false,
        });
    });
}
